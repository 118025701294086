import { useRef, useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  TextField,
  Grid2,
  Divider,
  Button,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  Pagination,
  CircularProgress,
} from "@mui/material";

import {
  faAdd,
  faArrowRight,
  faList,
  faTimes,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Add, ArrowForwardIos, Close, List, Sync } from "@mui/icons-material";

export default function NewInvoice(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [createItem, setCreateItem] = useState(false);
  const [listItem, setListItem] = useState(false);
  const [error, setError] = useState("");
  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [total, setTotal] = useState(0.0);
  const [subtotal, setSubTotal] = useState(0.0);
  const [vatStatus, setVATStatus] = useState("exclusive");
  const [vat, setVAT] = useState(0.0);
  const [currency] = useState("KSh");
  const [bdata, setBdata] = useState([]);
  const title = useRef();
  const date = useRef();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  useEffect(() => {
    let sbt = bdata.reduce(
      (sum, item) => sum + (item?.Rate || 0) * (item.Qty || 0),
      0
    );
    setSubTotal(sbt);
    if (vatStatus === "inclusive") {
      const vatAmount = (sbt * 0.16) / 1.16;
      setVAT(vatAmount);
      setTotal(sbt);
    } else {
      const vatAmount = sbt * 0.16;
      setVAT(vatAmount);
      setTotal(sbt + vatAmount);
    }
  }, [bdata, vatStatus]);

  const searchByPhone = (q) => {
    fetch(`/api/mobile/seachbyname/${q}`)
      .then((res) => res.json())
      .then((data) => setFO(data))
      .catch(() => setFO(null));
  };

  const createDocument = () => {
    if (!bdata.length) {
      setSnackbar({
        open: true,
        message: "You must add an invoice item!",
        severity: "error",
      });
      return;
    }
    if (!title.current.value || !date.current.value) {
      setSnackbar({
        open: true,
        message: "Please fill in all required fields!",
        severity: "error",
      });
      return;
    }
    const body = {
      Title: title.current.value,
      Date: date.current.value,
      ItemsList: bdata,
      SubTotal: subtotal,
      VAT: vat,
      WithholdingTax: 0,
      Total: total,
      VATStatus: vatStatus,
      CustomerID: fodata?.UserID,
      UserID: props.user.UserID,
    };

    setLoading(true);
    fetch("/api/invoices/create", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          navigate(`/invoices/preview?id=${data.id}`);
        } else {
          setSnackbar({
            open: true,
            message: data.error || "Failed to create invoice",
            severity: "error",
          });
        }
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: "Oops! Something went wrong!",
          severity: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box p={2}>
      <Card sx={{ p: 2, mb: 2, boxShadow: "0px 4px 8px #60606040" }}>
        <Grid2 container spacing={2} alignItems="center">
          <Grid2 size={{ xs: 6 }}>
            <Typography variant="h5">Create Invoice</Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }} display="flex" justifyContent="flex-end">
            <Button
              startIcon={<ArrowForwardIos icon={faArrowRight} />}
              onClick={() => navigate("/invoices/List")}
              variant="outlined"
            >
              Invoices
            </Button>
          </Grid2>
        </Grid2>
      </Card>

      <Card sx={{ p: 2, boxShadow: "0px 4px 8px #60606040" }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Typography variant="h6">Search Customer</Typography>
            <Divider sx={{ mb: 1 }} />
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              onChange={(e) => searchByPhone(e.target.value)}
              size="small"
            />
            {fo && (
              <Box
                mt={2}
                sx={{ border: "1px solid #ddd", p: 1, borderRadius: 1 }}
              >
                {fo.map((item, index) => (
                  <SOList
                    key={index}
                    item={item}
                    setCustomer={setFOData}
                    clearEvent={() => {
                      setFO(null);
                    }}
                  />
                ))}
              </Box>
            )}
            {fodata && (
              <SOItem
                item={fodata}
                clearEvent={() => {
                  setFOData(null);
                }}
              />
            )}
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6 }}>
            <Typography variant="h6">Invoice Details</Typography>
            <Divider sx={{ mb: 1 }} />
            <TextField
              fullWidth
              label="LPO/LSO/Title *"
              inputRef={title}
              variant="outlined"
              size="small"
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Due Date *"
              type="date"
              inputRef={date}
              variant="outlined"
              defaultValue={new Date().toISOString().split("T")[0]}
              size="small"
            />
          </Grid2>
        </Grid2>

        <Typography variant="h6" sx={{ mt: 3 }}>
          Billing Items
        </Typography>
        <Divider sx={{ mb: 1 }} />
        <Box sx={{ mb: 2, backgroundColor: "#FBFBFB", p: 1, borderRadius: 1 }}>
          <Grid2 container spacing={1}>
            <Grid2 size={{ xs: 4 }}>
              <Typography>Item</Typography>
            </Grid2>
            <Grid2 size={{ xs: 1 }}>
              <Typography>Qty</Typography>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <Typography>Rate (KSh)</Typography>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <Typography>Total (KSh)</Typography>
            </Grid2>
            <Grid2 size={{ xs: 1 }}>
              <Typography>Action</Typography>
            </Grid2>
          </Grid2>
          <Divider sx={{ my: 2 }} />
          {bdata.map((item, i) => (
            <BillingItem
              key={i}
              item={item}
              bdata={bdata}
              setBdata={setBdata}
            />
          ))}
        </Box>

        <Box display="flex" gap={1} sx={{ mb: 2 }}>
          <IconButton color="primary" onClick={() => setCreateItem(true)}>
            <Add icon={faAdd} />
          </IconButton>
          <IconButton color="primary" onClick={() => setListItem(true)}>
            <List icon={faList} />
          </IconButton>
        </Box>

        <Box mt={3} display="flex" flexDirection="column" alignItems="flex-end">
          <Typography variant="subtitle1">
            Sub Total ({currency}): {subtotal.toFixed(2)}
          </Typography>
          <RadioGroup
            row
            value={vatStatus}
            onChange={(e) => setVATStatus(e.target.value)}
          >
            <FormControlLabel
              value="exclusive"
              control={<Radio />}
              label="VAT Exclusive"
            />
            <FormControlLabel
              value="inclusive"
              control={<Radio />}
              label="VAT Inclusive"
            />
          </RadioGroup>
          <Typography variant="subtitle1">
            VAT (16%) ({currency}): {vat.toFixed(2)}
          </Typography>
          <Typography variant="h6">
            Total ({currency}): {total.toFixed(2)}
          </Typography>
        </Box>

        <Button
          onClick={createDocument}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Card>

      <CreateBillingItemDialog
        open={createItem}
        setOpen={setCreateItem}
        bdata={bdata}
        setBdata={setBdata}
      />
      <BillingListDialog
        open={listItem}
        setOpen={setListItem}
        bdata={bdata}
        setBdata={setBdata}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

// Helper components
function SOList({ item, setCustomer, clearEvent }) {
  return (
    <Box
      onClick={() => {
        setCustomer(item);
        clearEvent();
      }}
      sx={{ p: 1, cursor: "pointer", borderBottom: "1px solid #ddd" }}
    >
      <Typography variant="subtitle1">{item.Organisation}</Typography>
      <Typography variant="body2">
        <b>Contact:</b> {item.Name} - {item.Email}
      </Typography>
    </Box>
  );
}

function SOItem({ item, clearEvent }) {
  return (
    <Box
      mt={2}
      sx={{
        border: "1px solid #ddd",
        p: 2,
        borderRadius: 1,
        position: "relative",
      }}
    >
      <Typography variant="subtitle1">{item.Organisation}</Typography>
      <Typography variant="body2">
        <b>Contact:</b> {item.Name} - {item.Email}
      </Typography>
      <Typography variant="body2">
        <b>Customer ID:</b> {item.id}
      </Typography>
      <IconButton
        onClick={clearEvent}
        color="error"
        size="small"
        sx={{ mt: 1, position: "absolute", top: 1, right: 2 }}
      >
        <Close icon={faTimes} />
      </IconButton>
    </Box>
  );
}

function BillingItem({ item, bdata, setBdata }) {
  const handleDelete = () => {
    const updatedBdata = bdata.filter((_, i) => i !== bdata.indexOf(item));
    setBdata(updatedBdata);
  };

  const total = (item?.Rate * item?.Qty).toFixed(2);

  return (
    <Grid2
      container
      spacing={1}
      alignItems="center"
      sx={{ p: 1, borderBottom: "1px solid #ddd" }}
    >
      <Grid2 size={{ xs: 4 }}>
        <Typography>{item.Item}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 1 }}>
        <Typography>{item?.Qty}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <Typography>{item?.Rate}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <Typography>{total}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 1 }}>
        <IconButton onClick={handleDelete} color="error">
          <Close icon={faTimes} />
        </IconButton>
      </Grid2>
    </Grid2>
  );
}

function CreateBillingItemDialog({ open, setOpen, bdata, setBdata }) {
  const item = useRef();
  const qty = useRef();
  const rate = useRef();
  const [error, setError] = useState("");

  const addBillingItem = () => {
    if (!item.current.value || !qty.current.value || !rate.current.value) {
      return setError("All fields are required!");
    }
    const newItem = {
      Item: item.current.value,
      Qty: parseInt(qty.current.value),
      Rate: parseFloat(rate.current.value),
    };
    setBdata([...bdata, newItem]);
    setOpen(false);
    setError("");
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Add Billing Item</DialogTitle>
      <DialogContent>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <TextField label="Item" inputRef={item} fullWidth size="small" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <TextField
              label="Quantity"
              inputRef={qty}
              type="number"
              fullWidth
              size="small"
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <TextField
              label="Rate"
              inputRef={rate}
              type="number"
              fullWidth
              size="small"
            />
          </Grid2>
        </Grid2>
        {error && (
          <Typography color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={addBillingItem} variant="contained" color="primary">
          Add Item
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function BillingListDialog({ open, setOpen, bdata, setBdata }) {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editItem, setEditItem] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [sortOrder, setSortOrder] = useState("newest");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchBillingItems();
    }
  }, [open, searchTerm, sortOrder, page]);

  const fetchBillingItems = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `/api/invitems?search=${searchTerm}&sort=${sortOrder}&page=${page}&limit=6`
      );
      if (response.ok) {
        const result = await response.json();
        setData(result.items);
        setTotalPages(result.totalPages);
      } else {
        throw new Error("Failed to fetch billing items");
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to fetch billing items",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const addBillingItem = (item) => {
    setBdata([...bdata, item]);
    setOpen(false);
  };

  const deleteItem = (index) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
    setBdata(bdata.filter((item) => item.id !== data[index].id));
  };

  const updateItem = (index, updatedItem) => {
    const updatedData = [...data];
    updatedData[index] = updatedItem;
    setData(updatedData);
    setBdata(
      bdata.map((item) => (item.id === updatedItem.id ? updatedItem : item))
    );
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Select Billing Item</DialogTitle>
        <Divider sx={{ mb: 1 }} />
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
              mt: 1,
            }}
          >
            <TextField
              label="Search items"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ width: "60%" }}
              size="small"
            />
            <Select
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              sx={{ width: "30%" }}
              size="small"
            >
              <MenuItem value="newest">Newest</MenuItem>
              <MenuItem value="oldest">Oldest</MenuItem>
            </Select>
            <IconButton onClick={fetchBillingItems} disabled={loading}>
              <Sync icon={faSync} spin={loading} />
            </IconButton>
          </Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                minHeight: "50vh",
                my: 4,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid2 sx={{ minHeight: "50vh" }} container spacing={2}>
              {data.map((item, index) => (
                <Grid2 key={index} size={{ xs: 12, md: 6 }}>
                  <Box sx={{ border: "1px solid #ddd", p: 2, mb: 1 }}>
                    {editItem === index ? (
                      <>
                        <TextField
                          fullWidth
                          value={item.Item}
                          onChange={(e) =>
                            updateItem(index, { ...item, Item: e.target.value })
                          }
                          size="small"
                          sx={{ mb: 1 }}
                        />
                        <TextField
                          fullWidth
                          value={item.Qty}
                          onChange={(e) =>
                            updateItem(index, { ...item, Qty: e.target.value })
                          }
                          size="small"
                          sx={{ mb: 1 }}
                        />
                        <TextField
                          fullWidth
                          value={item.Rate}
                          onChange={(e) =>
                            updateItem(index, { ...item, Rate: e.target.value })
                          }
                          size="small"
                          sx={{ mb: 1 }}
                        />
                        <Button onClick={() => setEditItem(null)}>Save</Button>
                      </>
                    ) : (
                      <>
                        <Typography variant="subtitle1">{item.Item}</Typography>
                        <Typography>Qty: {item.Qty} pcs</Typography>
                        <Typography>
                          Rate: KSh {item?.Rate ? item?.Rate : 0}
                        </Typography>
                        <Typography>
                          Total: KSh {(item?.Rate * item?.Qty).toFixed(2)}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => addBillingItem(item)}
                            sx={{ mr: 1 }}
                            size="small"
                          >
                            Add
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => setEditItem(index)}
                            sx={{ mr: 1 }}
                            size="small"
                          >
                            Edit
                          </Button>
                          {bdata.some(
                            (bdataItem) => bdataItem.id === item.id
                          ) && (
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => deleteItem(index)}
                              size="small"
                            >
                              Delete
                            </Button>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid2>
              ))}
            </Grid2>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => setPage(value)}
              size="small"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
